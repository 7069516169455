import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CurrencyProvider } from './contexts/CurrencyContext';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './hooks/useAuth';
import { PrivateRoute } from './components/PrivateRoute';
import { AdminRoute } from './routes/AdminRoute';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { Providers } from './pages/Providers';
import { Compare } from './pages/Compare';
import { Contact } from './pages/Contact';
import { Onboarding } from './pages/Onboarding';
import { Checkout } from './pages/Checkout';
import { BusinessDetails } from './pages/BusinessDetails';
import { PaymentCallback } from './pages/PaymentCallback';
import { Pricing } from './pages/Pricing';
import { PaymentProviders } from './pages/PaymentProviders';
import { BecomePartner } from './pages/BecomePartner';
import { Blog } from './pages/Blog';
import { HelpCentre } from './pages/HelpCentre';
import { Login } from './pages/Login';
import UserDashboard from './pages/UserDashboard';
import AdminDashboard from './pages/AdminDashboard';
import LoadingSpinner from './components/LoadingSpinner';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { MagicLinkHandler } from './hooks/MagicLinkHandler';
import { BlogPost } from '@/pages/BlogPost';
import { Analytics } from "@vercel/analytics/react"

const AppContent: React.FC = () => {
  const { loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-black">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white overflow-x-hidden">
      <Toaster 
        position="top-right"
        toastOptions={{
          style: {
            background: '#1a1a1a',
            color: '#fff',
            border: '1px solid rgba(255,255,255,0.1)'
          }
        }}
      />
      <div className="fixed inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-900/20 via-black to-black pointer-events-none" />
      <div className="relative">
        <Header />
        <main className="min-h-screen w-full max-w-[100vw] overflow-x-hidden bg-black/95">
          <Routes>
            {/* Public Routes */}
            <Route index element={<Home />} />
            <Route path="onboarding" element={<Onboarding />} />
            <Route path="providers" element={<Providers />} />
            <Route path="compare" element={<Compare />} />
            <Route path="contact" element={<Contact />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="payment-providers" element={<PaymentProviders />} />
            <Route path="become-partner" element={<BecomePartner />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:slug" element={<BlogPost />} />
            <Route path="help" element={<HelpCentre />} />
            <Route path="login" element={<Login />} />

            {/* Protected User Routes */}
            <Route 
              path="dashboard" 
              element={
                <PrivateRoute>
                  <UserDashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="checkout" 
              element={
                  <Checkout />
              } 
            />
            <Route 
              path="payment/callback" 
              element={
                <PrivateRoute>
                  <PaymentCallback />
                </PrivateRoute>
              } 
            />
            <Route 
              path="business-details" 
              element={
                <PrivateRoute>
                  <BusinessDetails />
                </PrivateRoute>
              } 
            />

            {/* Admin Routes */}
            <Route 
              path="admin/*" 
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              } 
            />

            {/* Magic Link Verification Route */}
            <Route path="auth/verify" element={<MagicLinkHandler />} />

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <AuthProvider>
          <CurrencyProvider>
            <AppContent />
            <Analytics />
          </CurrencyProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;